import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Dropdown, Row, Typography } from 'antd';

import { Page, DashboardItem } from '@components';
import { authSelector, fetchUserApprovalGroup } from '@redux/slices/auth';
import {
    approvalSelector,
    draftsSelector,
    fetchApprovals,
    fetchDrafts,
    resetOrderStates,
} from '@redux/slices/order';
import { fetchScheduled, scheduledValues } from '@redux/slices/order/scheduled';
import { CustomMenu } from '@components/header/styled';
import { Text } from '@styles/components/text';
import { DashboardItemContainer } from '@components/dashboard-item/styled';
import { translate } from '@components/i18n';
import meta from '../meta.json';
import { COUNTRY } from '@config/env';
import { fetchTransferDraftOrders, transferDraftOrderSelector } from '@redux/slices/transfer-order/draft';

const Home: React.FC = () => {
    const authState = useSelector(authSelector);
    const draftsState = useSelector(draftsSelector);
    const scheduledState = useSelector(scheduledValues);
    const approvalState = useSelector(approvalSelector);
    const transferDraftState = useSelector(transferDraftOrderSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTransferDraftOrders({ page: 0, size: 20 }));
        dispatch(fetchDrafts());
        dispatch(resetOrderStates());
        dispatch(fetchScheduled());
        dispatch(fetchApprovals({ current: 1, pageSize: 9999 }));
        dispatch(fetchUserApprovalGroup());
    }, []);

    const getBadget = (description: string): number => {
        switch (description) {
            case 'home.cards.scheduled':
                return scheduledState?.length || 0;
            case 'home.cards.approvals':
                return approvalState.availableApprovals?.length || 0;
            case 'home.cards.draft':
                return draftsState.drafts.length || 0;
            case 'home.cards.transfeDraft':
                return transferDraftState?.available?.totalElements || 0;
            default: 0
        }
    }

    const topMenu = (
        <CustomMenu key="rel-menu" style={{ marginTop: 5 }}>
            {meta.sideMenus
                .find((m) => m.title === 'sideMenu.reports.self')
                ?.subMenus?.filter(
                    (m) =>
                        authState.currentUser.isAdmin ||
                        m.allowedRoles?.some((r) =>
                            authState.currentUser.roles.some((c) => c === r),
                        ),
                )
                .filter(sb => (sb.onlyCountries.includes("ALL") || sb.onlyCountries.includes(COUNTRY)))
                .map((m, i) => {
                    return (
                        <CustomMenu.Item key={i}>
                            <Link
                                to={m.path}
                                key={`side-menu-${translate(
                                    m.title,
                                )}-${i}-link`}
                            >
                                <Row gutter={12}>
                                    <Col>
                                        <Text
                                            blue
                                            fontWeight={400}
                                            fontSize={15}
                                        >
                                            {translate(m.title)}
                                        </Text>
                                    </Col>
                                </Row>
                            </Link>
                        </CustomMenu.Item>
                    );
                })}
        </CustomMenu>
    );

    return (
        <Page title={translate('pages.home')}>
            <Col span={24} style={{ marginTop: '50px' }}>
                <Row justify="center">
                    <Col span={2} />
                    <Col span={18}>
                        <Row justify="start">
                            <Typography.Title
                                level={3}
                                style={{ marginBottom: 0 }}
                            >
                                {translate('home.hello')}{' '}
                                {authState.currentUser?.name?.split(' ')[0]}
                            </Typography.Title>
                        </Row>
                        <Col>
                            <Row justify="start">
                                <Typography.Title
                                    level={3}
                                    style={{
                                        fontWeight: 400,
                                        marginBottom: 40,
                                    }}
                                >
                                    {translate('home.welcome')}
                                </Typography.Title>
                            </Row>
                        </Col>
                        <Col>
                            <Row style={{ rowGap: 30, columnGap: 30 }} wrap>
                                {meta.menus
                                    .filter(
                                        (m) =>
                                            authState.currentUser.isAdmin ||
                                            m.allowedRoles?.some((r) =>
                                                authState.currentUser.roles.some(
                                                    (c) => c === r,
                                                ),
                                            ),
                                    )
                                    .filter(sb => (sb.onlyCountries?.includes("ALL") || sb.onlyCountries?.includes(COUNTRY)))
                                    .map(({ badge, ...m }) => {
                                        return (
                                            <>
                                                {m.description !==
                                                    'home.cards.reports' && (
                                                        <DashboardItem
                                                            key={`dashboard-item-${translate(
                                                                m.title,
                                                            )}-${translate(
                                                                m.description,
                                                            )}`}
                                                            badge={badge ? getBadget(m.description) : null}
                                                            {...m}
                                                            title={translate(
                                                                m.title,
                                                            )}
                                                            description={translate(
                                                                m.description,
                                                            )}
                                                        />
                                                    )}
                                                {m.description ===
                                                    'home.cards.reports' && (
                                                        <Dropdown
                                                            overlay={topMenu}
                                                            trigger={['click']}
                                                            key={'rel'}
                                                            placement="topCenter"
                                                        >
                                                            <DashboardItemContainer
                                                                xs={24}
                                                                sm={24}
                                                                md={7}
                                                                lg={7}
                                                                xl={7}
                                                            >
                                                                <DashboardItem
                                                                    key={`dashboard-item-${translate(
                                                                        m.title,
                                                                    )}-${translate(
                                                                        m.description,
                                                                    )}`}
                                                                    title={translate(
                                                                        m.title,
                                                                    )}
                                                                    description={translate(
                                                                        m.description,
                                                                    )}
                                                                    icon={m.icon}
                                                                    path={''}
                                                                />
                                                            </DashboardItemContainer>
                                                        </Dropdown>
                                                    )}
                                            </>
                                        );
                                    })}
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Col>
        </Page>
    );
};

export default Home;
