import React from 'react';
import { Col, Row } from 'antd';
import { ShadowContainer } from '@styles/components/wrapper';
import { Text } from '@styles/components/text';
import '@extensions/number';
import moment from 'moment';
import { translate } from '@components/i18n';
import { useSelector } from 'react-redux';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';

export const TransferHistoryShowHeader: React.FC = () => {
    const transferOrder = useSelector(transferOrderSelector);

    const orderValues = !transferOrder?.order
        ? []
        : [
            {
                label: translate('columns.order'),
                value: transferOrder.order.id,
            },
            {
                label: translate('columns.client'),
                break: true,
                value: `${transferOrder.order.nomeCliente}`,
            },
            {
                label: translate('columns.distributor'),
                break: true,
                value: `${transferOrder.order.codigoDistribuidor}`,
            },
            {
                label: translate('columns.customerCity'),
                break: true,
                value: `${transferOrder.order.cidadeCliente}`,
            },
            {
                label: translate('columns.orderDate'),
                value: moment(transferOrder.order.dataPedido).format('DD/MM/YYYY'),
            },
            {
                label: translate('general.billed'),
                value: transferOrder.order.faturado ? 'Sim' : 'Não',
            },
        ];

    const orderStatus = !transferOrder?.order
        ? []
        : [
            {
                label: translate('columns.status'),
                value: transferOrder.order.status ? translate(`columns.${transferOrder.order.status}`) : '',
            },
            {
                label: translate('columns.division'),
                value: transferOrder.order.divisao,
            },
            {
                label: translate('columns.branch'),
                value: transferOrder.order.descricaoFilial,
            },
            {
                label: translate('columns.moneyType'),
                value: `${transferOrder?.moneyType?.codigo} - ${transferOrder?.moneyType?.descricao}`
            }
        ];

    return (
        <ShadowContainer style={{ padding: '1.5rem' }}>
            <Row gutter={10}>
                <Col span={24} lg={{ span: 8 }}>
                    {orderValues.map((c) => (
                        <Row style={{ marginTop: 10 }}>
                            <Text fontWeight={'bold'} blue marginRight={'4px'}>
                                {c.label}:
                            </Text>
                            <Text inline={!c.break} title={c.value?.toString()}>
                                {c.value}
                            </Text>
                        </Row>
                    ))}
                </Col>
                <Col span={24} lg={{ span: 6 }}>
                    {orderStatus
                        .filter((c) => c)
                        .map((c) => (
                            <Row style={{ marginTop: 10 }}>
                                <Text
                                    fontWeight={'bold'}
                                    blue
                                    marginRight={'4px'}
                                >
                                    {c.label}:
                                </Text>
                                <Text title={c.value}>
                                    {c.value}
                                </Text>
                            </Row>
                        ))}
                </Col>
            </Row>
        </ShadowContainer>
    );
};
