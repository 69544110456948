import React, { useEffect } from 'react';
import { Col } from 'antd';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Header } from './components/header';
import { Body } from './components/body';
import { CardTable } from '@components/table/styled';
import { translate } from '@components/i18n';
import { fetchTransferItems } from '@redux/slices/transfer-item';
import { fetchAllAvailablePlatforms } from '@redux/slices/order';
import { fetchTransferClients } from '@redux/slices/transfer-clients';
import { fetchDivisions } from '@redux/slices/divisions';
import { fetchAllItems } from '@redux/slices/campaign';

export const TransferItem: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDivisions())
        dispatch(fetchTransferItems());
        dispatch(fetchAllAvailablePlatforms());
        dispatch(fetchTransferClients({ size: 999, page: 0 }));
        dispatch(fetchAllItems())
    }, []);

    return (
        <Page
            title={translate('pages.transferItems')}
            greyBackground
            onClickGoBack
            menuIcon={<LeftOutlined />}
        >
            <Col span={22} style={{ alignSelf: 'center' }}>
                <h2 style={{ marginTop: '15px' }}>
                    {translate('titlePages.transferItems')}
                </h2>
                <CardTable>
                    <Header />
                    <Body />
                </CardTable>
            </Col>
        </Page>
    );
};
