import React, { useEffect, useState } from 'react';

import { Input } from '@components';
import { SHOW_PROTOCOL } from '@config/env';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { DraftsTable } from '@redux/slices/order';
import { historyColumns, filterTypesStatus, filterTypes } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { filterTypesOrderHistory } from '@pages/order/constants';
import { InputRange } from '@components/input';
import { translate } from '@components/i18n';
import { fetchTransferHistoryOrders, transferHistoryOrderSelector } from '@redux/slices/transfer-order/history';
import { TransferOrder } from '@models/transfer-order';
import { fetchMoneySymbols, fetchMoneyTypes, moneyTypeSelector } from '@redux/slices/money-type';

export const HistoryBody: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const moneyType = useSelector(moneyTypeSelector);
    const historyState = useSelector(transferHistoryOrderSelector);

    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        let checkValues = {};

        if (values['datas']) {
            const range = values['datas'];
            checkValues = {
                ...checkValues,
                dataInicio: range[0].format('DD/MM/YYYY'),
                dataFim: range[1].format('DD/MM/YYYY'),
            };
        } else {
            checkValues = {
                ...checkValues,
                dataInicio: undefined,
                dataFim: undefined,
            };
        }

        delete values.datas

        dispatch(
            fetchTransferHistoryOrders({
                ...checkValues,
                ...values,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchTransferHistoryOrders({
                filterType: form.getFieldValue("filterType"),
                status: form.getFieldValue("status"),
                filterValue: form.getFieldValue("filterValue"),
                size: 20,
                page: 0,
            }),
        );
    };


    const onSelect = (rowKey: DraftsTable): void => {
        history.push(`/transfer-order/show?ref=${rowKey.id}`);
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferOrder.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(
            fetchTransferHistoryOrders(params),
        );
    };

    const start = (): void => {
        dispatch(
            fetchTransferHistoryOrders({
                size: 20,
                page: 0,
            }),
        );

        if (!moneyType?.isSuccess) {
            dispatch(fetchMoneyTypes())
        }

        if (!moneyType?.isSuccessSymbols) {
            dispatch(fetchMoneySymbols())
        }

    };

    useEffect(() => {
        start();
    }, []);

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={historyColumns}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        pointer
                        dataSource={historyState?.available?.content || []}
                        pagination={{
                            current: (historyState?.available?.number || 0) + 1,
                            pageSize: historyState?.available?.size || 10,
                            total: historyState?.available?.totalElements || 0
                        }}
                        isLoading={historyState?.isFetching}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name={'filterType'}
                            >
                                <Select
                                    placeholder={translate('forms.placeholders.chooseFilter')}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.date')}
                                name={'datas'}
                            >
                                <InputRange
                                    format="DD-MM-YYYY"
                                    placeholder={[
                                        translate('forms.placeholders.startDate'),
                                        translate('forms.placeholders.endDate'),
                                    ]}
                                    light
                                />
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.status')}
                                name={'status'}
                            >
                                <Select
                                    placeholder={translate('general.all')}
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {filterTypesStatus.map((c) => (
                                        <Option key={c.name} value={c.name}>
                                            {c.description}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => form.setFieldsValue({ filterValue: e.target.value })}
                                />
                            </Form.Item>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
