import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { TransferItem } from '@models/transfer-item';
import { RootState } from '@redux/reducers';
import { TransferOrder } from '@models/transfer-order';
import { exportReport } from '@utils/file';

export const fetchTransferHistoryOrders = 
    createAsyncThunk<PaginatedResponseApi<TransferOrder.Response>, TransferOrder.Params | void>('transfer-items/list-items', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<TransferItem.ResposeApi>>(`/pedidotransfer/list/historico`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return {
                ...data,
                content: data.content.map(item => {
                    const temp = Object.assign(new TransferOrder.ResposeApi(), item)
                    
                    return temp.fromJSON()
                })
            }
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});
export const fetchTransferReports = 
    createAsyncThunk<PaginatedResponseApi<TransferOrder.Response>, TransferOrder.Params | void>('transfer-history/reports', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<TransferItem.ResposeApi>>(`/relatorio/pedidostransfer`, { params: request });

        const { data, status } = response;

        if (status === 200) {
            const aux = {
                ...data,
                content: data.content.map(item => {
                    const temp = Object.assign(new TransferOrder.ResposeApi(), item)
                    
                    return temp.fromJSON()
                })
            }
            return aux 
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

export const exportTransferOrderHistory = createAsyncThunk<void,TransferOrder.Params | void>('transfer-history/resports-export', async (request, thunkAPI) => {
    try {
        const response = await axios.get(`/relatorio/pedidostransfer/export`, { params: request, responseType: 'blob' });

        const { data, status } = response;

        if (status === 200) {
            exportReport(data,`${translate('general.reportTransferOrderHistory')}.xlsx`);
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

interface ITransferHistoryOrderState {
    available: PaginatedResponseApi<TransferOrder.Response>;
    reports: PaginatedResponseApi<TransferOrder.Response>;

    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;

    isFetchingReports: boolean;
    isSuccessReports: boolean;
    isErrorReports: boolean;
}

const initialState: ITransferHistoryOrderState = {
    available: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    reports: undefined,
    isFetchingReports: false,
    isSuccessReports: false,
    isErrorReports: false,
};

const transferHistoryOrderSlice = createSlice({
    name: 'transfer-history-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        }
    },
    extraReducers: {
        [fetchTransferHistoryOrders.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferHistoryOrders.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchTransferHistoryOrders.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferOrder.Response>>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
        [fetchTransferReports.pending.toString()]: (state) => {
            state.isFetchingReports = true;
            return state;
        },
        [fetchTransferReports.rejected.toString()]: (state, action) => {
            state.isFetchingReports = false;
            state.isErrorReports = true;
            return state;
        },
        [fetchTransferReports.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferOrder.Response>>,
        ) => {
            state.reports = payload;
            state.isSuccessReports = true;
            state.isFetchingReports = false;
            return state;
        },
    },
});

export const { clearState } = transferHistoryOrderSlice.actions;

export const transferHistoryOrderSelector = (state: RootState): ITransferHistoryOrderState => state.transferHistoryOrder;

export default transferHistoryOrderSlice.reducer;
