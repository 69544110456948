import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { Title } from '@styles/components/text';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CustomerResponse } from '@models/customer';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { SearchOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { fetchTransferClients, transferClientsSelector } from '@redux/slices/transfer-clients';
import { TransferClient } from '@models/transfer-client';
import { columns, filterTypes } from './constants';
import { clearTransferOrder, updateTransferClient } from '@redux/slices/transfer-order/order';

export const SelectClient: React.FC = () => {
    const transferClients = useSelector(transferClientsSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferClient.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(fetchTransferClients(params));
    };

    const onSelect = (rowKey: TransferClient.Response): void => {
        dispatch(updateTransferClient({ client: rowKey }));
        history.push('/create-transfer-order/select-distributor');
    };

    const search = (): void => {
        dispatch(
            fetchTransferClients({
                filterType: form.getFieldValue("filterType") || 'TODOS',
                filterString: form.getFieldValue("filterString"),
                page: 0,
                size: transferClients?.available?.size || 20,
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchTransferClients({
            page: 0,
            size: 20,
        }))

        const filters = Object.fromEntries(
            new URLSearchParams(location.search),
        );

        setTimeout(() => {
            form.setFieldsValue(filters);
        }, 1000);

        dispatch(clearTransferOrder())
    }, []);

    return (
        <Page
            title={translate('pages.searchCliente')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.searchCliente')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    columns={columns}
                                    form={form}
                                    pointer
                                    rowKey={(record: TransferClient.Response) => `${record.id}`}
                                    dataSource={transferClients?.available?.content || []}
                                    onRowClick={onSelect}
                                    pagination={{
                                        current: (transferClients?.available?.number || 0) + 1,
                                        pageSize: transferClients?.available?.size || 10,
                                        total: transferClients?.available?.totalElements || 0
                                    }}
                                    isLoading={transferClients?.isFetching}
                                    onChange={loadData}
                                    filters={[
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchFor',
                                            )}
                                            name={'filterType'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                                onChange={e => form.setFieldsValue({ filterType: e })}
                                            >
                                                {filterTypes.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.search',
                                                )}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    form.setFieldsValue({ filterString: e.target.value })
                                                }
                                            />
                                        </Form.Item>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
