import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { Description } from '@components/texts';
import { translate } from '@components/i18n';
import { Collapsable } from '../../order/components/styled';
import { transferOrderSelector } from '../../../redux/slices/transfer-order/order';

const { Panel } = Collapsable;


export const ClientCollapse: React.FC = memo(() => {
    const transferOrder = useSelector(transferOrderSelector);

    return (
        <Collapsable
            expandIconPosition="right"
            collapsible="header"
        >
            <Panel
                header={
                    <>
                        <Col>
                            {transferOrder?.ref && (
                                <Description
                                    text={`${translate('columns.order')}:`}
                                    description={transferOrder?.ref}
                                />
                            )}
                            <Description
                                text={`${translate('columns.client')}:`}
                                description={transferOrder?.client?.nome}
                            />
                            <Description
                                text={`${translate('columns.cnpj')}:`}
                                description={transferOrder?.client?.cnpj}
                            />
                        </Col>
                    </>
                }
                key='header'
            >
                <Col>
                    <Description
                        text={`${translate('columns.email')}:`}
                        description={transferOrder?.client?.email}
                    />
                    <Description
                        text={`${translate('columns.address')}:`}
                        description={transferOrder?.client?.endereco1}
                    />
                    <Description
                        text={`${translate('columns.neighborhood')}:`}
                        description={transferOrder?.client?.bairro}
                    />
                    <Description
                        text={`${translate('columns.city')}:`}
                        description={transferOrder?.client?.cidade}
                    />
                    <Description
                        text={`${translate('columns.state')}:`}
                        description={transferOrder?.client?.zona}
                    />
                </Col>
            </Panel>
        </Collapsable>
    );
},
);
