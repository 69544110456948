import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import {
    LoginResponse,
    LoginRequest,
    LoginResponseAPI,
} from '@models/auth/login';
import { RootState } from '@redux/reducers';
import { UserResponse, UserResponseApi } from '@models/users';
import {
    ApprovalGroupResponse,
    ApprovalGroupResponseApi,
} from '@models/approvalGroup';
import { addNotification } from '../notification';
import { ThunkCallback } from '../model/thunk-callback';
import { translate } from '@components/i18n';
import { ROLES_ENUM } from 'src/enums/user';

export interface ISignUpUser {
    name: string;
    email: string;
    password: string;
    phone: string;
    cpf: string;
    roles: string[];
}

export const signInUser = createAsyncThunk<UserResponse, LoginRequest>(
    'auth/login',
    async (signInData, thunkAPI) => {
        try {
            const response = await axios.post<LoginResponseAPI>(
                '/autenticacao/login',
                {
                    login: signInData.email,
                    senha: signInData.password,
                },
            );

            localStorage.setItem('jwtToken', response.data.token);

            const userData = await axios.get<UserResponseApi>(
                '/usuario/' + response.data.id,
            );

            const instance = Object.assign(
                new UserResponseApi(),
                userData.data,
            );

            const { data, status } = response;

            if (status === 200) {
                console.debug(response.data.papeis);
                return instance.toUserResponse(response.data.papeis);
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            // TODO: here will be fetch's actions
            console.debug(e);
            return thunkAPI.rejectWithValue(
                translate('general.userOrPasswordError'),
            );
        }
    },
);

export const fetchUserApprovalGroup = createAsyncThunk<ApprovalGroupResponse>(
    'auth/fetchUserApprovalGroup',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const outScope = JSON.parse(JSON.stringify(state));
            const { currentUser } = outScope.login;
            console.debug(currentUser);

            if (
                currentUser &&
                currentUser.grupoAprovacaoBonificacaoMarketingId
            ) {
                const response = await axios.get<ApprovalGroupResponseApi>(
                    '/grupoaprovacao/' +
                        currentUser.grupoAprovacaoBonificacaoMarketingId,
                );

                console.debug(currentUser);

                if (response.status === 200) {
                    const instance = Object.assign(
                        new ApprovalGroupResponseApi(),
                        response.data,
                    );
                    return instance.toGroupApprovalResponse();
                }
            }
            return undefined;
        } catch (e) {
            console.debug('cariu aqui', e);
            const { response } = e;
        }
    },
);

export const changeCurrentUser = createAsyncThunk<
    UserResponse,
    ThunkCallback<Partial<LoginRequest>>
>('users/changeCurrentUser', async (req, thunkAPI) => {
    try {
        const response = await axios.post<LoginResponseAPI>(
            '/autenticacao/login/admin',
            {
                login: req.data.email,
            },
        );

        localStorage.clear();

        localStorage.setItem('jwtToken', response.data.token);

        const userData = await axios.get<UserResponseApi>(
            '/usuario/' + response.data.id,
        );

        const instance = Object.assign(new UserResponseApi(), userData.data);

        const { data, status } = response;

        if (status === 200) {
            console.debug(response.data.papeis);
            req.onSuccess();
            return instance.toUserResponse(response.data.papeis);
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (e) {
        thunkAPI.dispatch(
            addNotification({
                type: 'error',
                message: translate('general.errorChangeLogin'),
                title: translate('general.errorT'),
                notificationKey: '',
            }),
        );
        return thunkAPI.rejectWithValue('');
    }
});

interface IAuthState {
    username: string;
    email: string;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage: string;
    currentUser?: UserResponse;
    isAdmin: boolean
    approvalGroup?: ApprovalGroupResponse;
}

const initialState: IAuthState = {
    username: '',
    email: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    isAdmin: false,
    errorMessage: '',
    approvalGroup: undefined,
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorMessage = '';
            state.approvalGroup = undefined;
            state.isAdmin = false;
            state.currentUser = undefined;


            return state;
        },
        logoutUser: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorMessage = '';
            state.currentUser = undefined;
            state.isAdmin = false;

            localStorage.removeItem('token');
            return state;
        },
        updateLoggedUser: (state, action: PayloadAction<UserResponse>) => {
            state.currentUser = action.payload;
            state.isAdmin = action.payload.roles.includes(ROLES_ENUM.ADMINISTRADOR)
            return state;
        },
    },
    extraReducers: {
        [signInUser.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<UserResponse>,
        ) => {
            state.currentUser = payload;
            state.isFetching = false;
            state.isSuccess = true;
            state.isAdmin = payload.roles.includes(ROLES_ENUM.ADMINISTRADOR)
            return state;
        },
        [signInUser.rejected.toString()]: (state, action) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        [signInUser.pending.toString()]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },

        [changeCurrentUser.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<UserResponse>,
        ) => {
            state.currentUser = payload;
            state.isAdmin = payload.roles.includes(ROLES_ENUM.ADMINISTRADOR)
            return state;
        },
        [changeCurrentUser.rejected.toString()]: (state) => {
            state.isFetching = false;
        },
        [changeCurrentUser.pending.toString()]: (state) => {
            state.isFetching = true;
        },
        [fetchUserApprovalGroup.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<ApprovalGroupResponse>,
        ) => {
            console.debug(payload);
            state.approvalGroup = payload;
        },
    },
});

export const isAuthenticated = (state: RootState): boolean =>
    state.login.isSuccess;

export const authSelector = (state: RootState): IAuthState => state.login;

export const { clearState, logoutUser, updateLoggedUser } = authSlice.actions;

export default authSlice.reducer;
