import moment, { Moment } from "moment";

export class TransferOrderItemResponseApi {
  numeroItemTransfer: string
  descricaoItemTransfer: string
  quantidade: number
  precoUnitario: number
  precoTotal: number
  multiplo: number

  public fromJSON(): TransferOrderItemResponse{
    const data: TransferOrderItemResponse = {
      descricaoItemTransfer: this.descricaoItemTransfer,
      numeroItemTransfer: this.numeroItemTransfer,
      quantidade: this.quantidade,
      precoUnitario: this.precoUnitario,
      precoTotal: this.precoTotal,
      multiplo: this.multiplo
    }
    return Object.assign(new TransferOrderItemResponse(), data);
  }
}

export class TransferOrderResponseApi {    
  id: number
  promotorId: number
  promotorNome: string
  emailCopia: string
  comentario: string
  dataPedido: string
  faturado: boolean
  codigoMoeda: string
  dataFaturamento: string
  valorTotal: number
  status: string
  divisao: string
  codigoCliente: number
  nomeCliente: string
  cnpjCliente: string
  cidadeCliente: string
  zonaCliente: string
  codigoFilial: number
  descricaoFilial: string
  codigoDistribuidor: number
  pedidoItens: TransferOrderItemResponseApi[]
  idCliente: number

  public fromJSON(): TransferOrderResponse {
    const data = {
      id: this.id,
      promotorId: this.promotorId,
      promotorNome: this.promotorNome,
      emailCopia: this.emailCopia,
      comentario: this.comentario,
      dataPedido: this.dataPedido? moment(this.dataPedido) : undefined,
      faturado: !!this.faturado,
      dataFaturamento: this.dataFaturamento? moment(this.dataFaturamento) : undefined,
      valorTotal: this.valorTotal,
      status: this.status,
      divisao: this.divisao,
      codigoCliente: this.codigoCliente,
      nomeCliente: this.nomeCliente,
      cnpjCliente: this.cnpjCliente,
      cidadeCliente: this.cidadeCliente,
      zonaCliente: this.zonaCliente,
      codigoFilial: this.codigoFilial,
      descricaoFilial: this.descricaoFilial,
      codigoDistribuidor: this.codigoDistribuidor,
      idCliente: this.idCliente,
      codigoMoeda: this.codigoMoeda,
      pedidoItens: this?.pedidoItens?.map(item => {
        return Object.assign(new TransferOrderItemResponseApi(), item).fromJSON()
      }) || []
    }

    return Object.assign(new TransferOrderResponse(), data);
  }
}

export class TransferOrderItemResponse {
  numeroItemTransfer: string
  descricaoItemTransfer: string
  quantidade: number
  precoUnitario: number
  precoTotal: number
  multiplo: number
}

export class TransferOrderResponse {
  id: number
  promotorId: number
  promotorNome: string
  emailCopia: string
  comentario: string
  dataPedido: Moment
  codigoMoeda: string
  faturado: boolean
  dataFaturamento: Moment
  valorTotal: number
  status: string
  divisao: string
  codigoCliente: number
  nomeCliente: string
  cnpjCliente: string
  cidadeCliente: string
  zonaCliente: string
  codigoFilial: number
  descricaoFilial: string
  codigoDistribuidor: number
  pedidoItens: TransferOrderItemResponse[]
  idCliente: number
}
