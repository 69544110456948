import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { TransferItem } from '@models/transfer-item';
import { RootState } from '@redux/reducers';
import { TransferOrder } from '@models/transfer-order';

export const fetchTransferDraftOrders = 
    createAsyncThunk<PaginatedResponseApi<TransferOrder.Response>, TransferOrder.Params | void>('transfer-items/list-items', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<TransferItem.ResposeApi>>(`/pedidotransfer/list/rascunhos`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return {
                ...data,
                content: data.content.map(item => {
                    const temp = Object.assign(new TransferOrder.ResposeApi(), item)
                    
                    return temp.fromJSON()
                })
            }
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingItems'));
    }
});

interface ITransferDraftOrderState {
    available: PaginatedResponseApi<TransferOrder.Response>;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
}

const initialState: ITransferDraftOrderState = {
    available: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const transferDraftOrderSlice = createSlice({
    name: 'transfer-draft-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        }
    },
    extraReducers: {
        [fetchTransferDraftOrders.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchTransferDraftOrders.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchTransferDraftOrders.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<TransferOrder.Response>>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
    },
});

export const { clearState } = transferDraftOrderSlice.actions;

export const transferDraftOrderSelector = (state: RootState): ITransferDraftOrderState => state.transferDraftOrder;

export default transferDraftOrderSlice.reducer;
