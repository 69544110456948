import { Moment } from "moment";

export class TransferItemRequestParams {
    query?: string;
    filterType?: string;
    page: number;
    size: number;
    sort?: string;
}

export class TransferItemRequest {
    cliente: number;
    descricao: string;
    divisao: string;
    enabled: boolean;
    filial: number;
    multiplo: number;
    numero: string;
    preco: number;
    vigencia: Moment;

    public toJSON(): TransferItemRequestApi {
        return ({
            cliente: this.cliente,
            descricao: this.descricao,
            divisao: this.divisao,
            enabled: !!this.enabled,
            filial: this.filial,
            multiplo: this.multiplo,
            numero: this.numero,
            preco: this.preco,
            vigencia: this?.vigencia?.toISOString(false),
        })
    }
}

export class TransferItemUpdateRequest extends TransferItemRequest{
    id: number;
}

export class TransferItemRequestApi {
    cliente: number;
    descricao: string;
    divisao: string;
    enabled: boolean;
    filial: number;
    multiplo: number;
    numero: string;
    preco: number;
    vigencia: string;
}
