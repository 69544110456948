import React, { memo, useContext, useEffect } from 'react';
import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { columns } from '../constants';
import { EditModal } from './edit-modal';
import { fetchTransferClients, transferClientsSelector } from '@redux/slices/transfer-clients';
import { TransferClient } from '@models/transfer-client';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { FormItem, Option, Select } from '@styles/components/form';
import { Input } from '@components/input';
import { SearchOutlined } from '@ant-design/icons';
import { filterTypes } from '@pages/transfer-order/select-client/constants';
import { ExportButton } from '@styles/components/button';

export const Body: React.FC = memo(() => {
    const dispatch = useDispatch();
    const clientState = useSelector(transferClientsSelector)
    const modalContext = useContext(ModalContext);

    const [form] = Form.useForm()

    useEffect(() => {
        dispatch(fetchTransferClients());
    }, [])

    const edit = (
        transferClient: TransferClient.Response,
        _,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        if (
            !`${event.target}`.includes('SVG') &&
            !`${event.target}`.includes('Button') &&
            !`${event.target}`.includes('Span')
        )
            modalContext.setIsOpen(
                true,
                <EditModal
                    data={transferClient}
                    key={`editing-client-${transferClient.nome.split(' ')[0]}`}
                />,
                {
                    title: `${translate('general.editClient')} - ${transferClient.nome.split(' ')[0]
                        }`,
                    width: '40%',
                },
            );
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        _: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferClient.Params = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(fetchTransferClients(params));
    };

    const search = (): void => {
        dispatch(
            fetchTransferClients({
                filterType: form.getFieldValue("filterType") || 'TODOS',
                filterString: form.getFieldValue("filterString"),
                page: 0,
                size: clientState?.available?.size || 20,
            }),
        );
    };

    const add = (): void => {
        modalContext.setIsOpen(true, <EditModal />, {
            title: translate('general.registerItem'),
            width: '40%',
        });
    };

    return (
        <Col>
            <Table
                columns={columns}
                rowKey={(record: TransferClient.Response) => `${record.id}`}
                dataSource={clientState?.available?.content || []}
                pagination={{
                    current: (clientState?.available?.number || 0) + 1,
                    pageSize: clientState?.available?.size || 10,
                    total: clientState?.available?.totalElements || 0,
                }}
                pointer
                onRowClick={edit}
                scroll={{ y: 'calc(100vh - 380px)' }}
                onChange={loadData}
                isLoading={clientState.isFetching}
                actions={[
                    <ExportButton onClick={add}>
                        {translate('forms.buttons.addNew')}
                    </ExportButton>
                ]}
                filters={[
                    <FormItem
                        label={translate(
                            'forms.labels.searchFor',
                        )}
                        name={'filterType'}
                    >
                        <Select
                            placeholder={translate(
                                'general.all',
                            )}
                            dropdownMatchSelectWidth={false}
                            allowClear
                            onChange={e => form.setFieldsValue({ filterType: e })}
                        >
                            {filterTypes.map((f) => {
                                return (
                                    <Option
                                        key={f.name}
                                        value={f.name}
                                    >
                                        {f.description}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>,
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Input
                            placeholder={translate(
                                'forms.placeholders.search',
                            )}
                            allowClear
                            suffix={
                                <div
                                    onClick={search}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <SearchOutlined />
                                </div>
                            }
                            onKeyUp={(e) => {
                                if (
                                    e.code === `Enter` ||
                                    e.keyCode === 13
                                ) {
                                    search();
                                }
                            }}
                            onChange={(e) =>
                                form.setFieldsValue({ filterString: e.target.value })
                            }
                        />
                    </Form.Item>,
                ]}
            />
        </Col>
    );
});
