import React, { useEffect, useState } from 'react';

import { Input } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { DraftsTable } from '@redux/slices/order';
import { historyColumns, filterTypes } from '../constants';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { translate } from '@components/i18n';
import { exportTransferOrderHistory, fetchTransferReports, transferHistoryOrderSelector } from '@redux/slices/transfer-order/history';
import { TransferOrder } from '@models/transfer-order';
import { getFilterOptions } from '@utils/filter';
import { ExportButton } from '@styles/components/button';
import { fetchMoneySymbols, moneyTypeSelector } from '@redux/slices/money-type';

export const HistoryBody: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const moneyType = useSelector(moneyTypeSelector)
    const historyState = useSelector(transferHistoryOrderSelector);

    const [form] = Form.useForm();

    const [queryState, setQueryState] = useState<string>('');
    const [optionsComparisonFilter, setOptionsComparisonFilter] = useState(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        let checkValues = {};

        if (changedValues.filterType) {
            setOptionsComparisonFilter(
                getFilterOptions(filterTypes, changedValues.filterType),
            );
        }

        dispatch(
            fetchTransferReports({
                ...checkValues,
                ...values,
                filterType: form.getFieldValue('filterType'),
                filterString: queryState,
                page: 0,
                size: 20,
            }),
        );
    };

    const search = (): void => {
        dispatch(
            fetchTransferReports({
                filterType: form.getFieldValue('filterType'),
                comparisonType: form.getFieldValue("comparisonType"),
                filterValue: queryState,
                size: 20,
                page: 0,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    const onSelect = (rowKey: DraftsTable): void => {
        history.push(`/transfer-order/show?ref=${rowKey.id}`);
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        const params: TransferOrder.Params = {
            filterType: form.getFieldValue('filterType'),
            comparisonType: form.getFieldValue("comparisonType"),
            filterValue: queryState,
            page: pagination.current - 1,
            size: pagination.pageSize,
        }

        if (sorter.order && sorter.field) {
            Object.assign(params, {
                sort: `${sorter.field},${sorter.order.substring(0, 3)}`
            })
        }

        dispatch(fetchTransferReports(params));
    };

    const start = (): void => {
        dispatch(
            fetchTransferReports({
                size: 20,
                page: 0,
            }),
        );

        if (!moneyType?.isSuccessSymbols) {
            dispatch(fetchMoneySymbols())
        }
    };

    const onExport = (): void => {
        dispatch(exportTransferOrderHistory({
            comparisonType: form.getFieldValue("comparisonType"),
            filterType: form.getFieldValue("filterValue"),
            filterValue: form.getFieldValue("filterValue"),
            page: 0,
            size: 999,
        }));
    };

    useEffect(() => {
        setOptionsComparisonFilter(getFilterOptions(filterTypes, 'PEDIDO'));
        start();
    }, []);

    return (
        <ShadowContainer>
            <Row justify="center">
                <Col span={23}>
                    <Table
                        columns={historyColumns}
                        rowKey={(record: DraftsTable) => `${record.id}`}
                        pointer
                        dataSource={historyState?.reports?.content || []}
                        pagination={{
                            current: (historyState?.reports?.number || 0) + 1,
                            pageSize: historyState?.reports?.size || 10,
                            total: historyState?.reports?.totalElements || 0
                        }}
                        isLoading={historyState?.isFetchingReports}
                        onRowClick={onSelect}
                        onFilterChange={onFiltersChange}
                        onChange={loadData}
                        form={form}
                        filters={[
                            <FormItem
                                label={translate('forms.labels.searchFor')}
                                name="filterType"
                                style={{ width: '200px' }}
                            >
                                <Select
                                    placeholder={translate('forms.placeholders.chooseFilter')}
                                    allowClear
                                >
                                    {filterTypes.map((f) => {
                                        return (
                                            <Option key={f.name} value={f.name}>
                                                {f.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>,
                            <FormItem
                                label={translate('forms.labels.searchType')}
                                name="comparisonType"
                            >
                                <Select
                                    placeholder="Todos"
                                    dropdownMatchSelectWidth={false}
                                    allowClear
                                >
                                    {optionsComparisonFilter?.map(
                                        (f) => {
                                            return (
                                                <Option
                                                    key={f.name}
                                                    value={f.name}
                                                >
                                                    {f.description}
                                                </Option>
                                            );
                                        },
                                    )}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate('forms.placeholders.search')}
                                    allowClear
                                    suffix={
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={search}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) => onSearch(e.target.value)}
                                />
                            </Form.Item>,
                        ]}
                        actions={[
                            <ExportButton
                                onClick={onExport}
                            >
                                {translate('general.export')}{' '}
                                <DownloadOutlined />
                            </ExportButton>,
                        ]}
                    />
                </Col>
            </Row>
        </ShadowContainer>
    );
};
