import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import { translate } from '@components/i18n';
import { transferOrderSelector } from '@redux/slices/transfer-order/order';

export const CardHeader: React.FC = memo(() => {
    const { client } = useSelector(transferOrderSelector);

    return (
        <Col>
            <Row style={{ paddingLeft: 10 }}>
                <Typography.Text
                    style={{
                        fontWeight: 300,
                        fontSize: 16,
                    }}
                >
                    {translate('forms.labels.client')}:
                    <Typography.Text strong style={{ marginLeft: 5 }}>
                        {client?.nome}
                    </Typography.Text>
                </Typography.Text>
            </Row>
            <Row style={{ paddingLeft: 10 }}>
                <Typography.Text style={{ fontWeight: 300, fontSize: 16 }}>
                    {translate('forms.labels.cnpj')}:
                    <Typography.Text strong style={{ marginLeft: 5 }}>
                        {client?.cnpj}
                    </Typography.Text>
                </Typography.Text>
            </Row>
        </Col>
    );
});
