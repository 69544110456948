/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EditableField } from '@models/editable-field';
import { translate } from '@components/i18n';
import { useSelector } from 'react-redux';
import { authSelector } from '@redux/slices/auth';
import { userSelector } from '@redux/slices/users';

export const columns = [
    {
        title: translate('columns.name'),
        dataIndex: 'nome',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.email'),
        dataIndex: 'email',
        sorter: true,
        width: '15%'
    },
    {
        title: translate('columns.promoter'),
        dataIndex: 'promotor',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            const users = useSelector(userSelector)
            const user = users?.availableUsers?.find(user => user.jdeCode === record.codigoPromotor)
            return (
                <span>
                    {user?.name || ''}
                </span>
            );
        },
    },
    {
        title: translate('columns.city'),
        dataIndex: 'cidade',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'zona',
        sorter: true,
        width: '10%',
    },
];

export function editableFields({
    promoters,
}): EditableField[] {
    const { isAdmin, currentUser } = useSelector(authSelector)

    if (isAdmin) {
        promoters.unshift({
            id: currentUser.jdeCode,
            name: `${currentUser.jdeCode} - (Eu) ${currentUser.name}`,
        })
    }

    const fields = [
        {
            name: 'nome',
            label: translate('forms.labels.fullName'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.name'),
                },
                {
                    min: 5,
                    message: translate('forms.rules.fiveCaracteres'),
                },
            ],
        },
        {
            name: 'cnpj',
            label: translate('forms.labels.cnpj'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'email',
            label: translate('forms.labels.email'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.email'),
                },
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: 'codigoPromotor',
            label: translate('forms.labels.promotor'),
            type: 'select',
            span: 24,
            options: promoters,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'endereco1',
            label: translate('forms.labels.address1'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'endereco2',
            label: translate('forms.labels.address2'),
            type: 'text',
            span: 24,
        },
        {
            name: 'bairro',
            label: translate('forms.labels.neighborhood'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'cidade',
            label: translate('forms.labels.city'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
        {
            name: 'zona',
            label: translate('forms.labels.state'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.obg'),
                },
            ],
        },
    ];

    if (!isAdmin) {
        return fields.filter(item => item.name !== 'codigoPromotor') as EditableField[]
    }


    return fields as EditableField[]
}
