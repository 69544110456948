import React from 'react';

import { Page } from '@components';
import { Col, Row } from 'antd';
import { Title } from '@styles/components/text';
import { HistoryBody } from './components/history-body';
import { translate } from '@components/i18n';

export const TransferOrderHistory: React.FC = () => {
    return (
        <Page
            title={translate('pages.historyOfOrders')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.historyOfOrders')}
                        </Title>
                    </Row>
                    <HistoryBody />
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
