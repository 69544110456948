import { DownloadOutlined } from '@ant-design/icons';
import { SearchGroup } from '@components/input';
import { Col, Row } from 'antd';
import React, { memo } from 'react';
import { SelectItemsWarning } from '../../order/components/select-items-warning';
import { useDispatch, useSelector } from 'react-redux';
import { ExportButton } from '@styles/components/button';
import { utils, writeFile } from 'xlsx';
import '@extensions/number';
import moment from 'moment';
import { SelectCampaign } from '../../order/components/select-campaigns';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { ITransferItemsFilter, transferOrderMoneySymbolSelector, transferOrderSelector, updateTransferFilter } from '@redux/slices/transfer-order/order';
import { exportTransferItemsColumns, filterItemsOptions } from '../select-items/constants';

interface SelectItemsHeaderProps {
    readOnly?: boolean;
    marketing?: boolean;
    bonification?: boolean;
}

export const SelectItemsHeaderFooter: React.FC<SelectItemsHeaderProps> = memo(
    ({ bonification = false }) => {
        const dispatch = useDispatch();
        const transferOrder = useSelector(transferOrderSelector);
        const moneySymbol = useSelector(transferOrderMoneySymbolSelector)


        const filter = (filter: Partial<ITransferItemsFilter>): void => {
            dispatch(updateTransferFilter(filter));
        };

        function exportToExcel(): void {
            let htmls = `<table id="tbl_exporttable_to_xls" border="1">
            <thead>
                <th>${translate('columns.date')}: ${moment().format('DD-MM-YYYY')}</th>
                <th>${translate('columns.branch')}: ${transferOrder.distributor.tipoDescricao}</th>
            </thead>
             <thead>
                <th>${translate('columns.code')}: ${transferOrder.client.id}</th>
                <th>${translate('columns.client')}: ${transferOrder.client.nome}</th>
            </thead>
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>${translate('columns.orderAmount')}:</th>
              <th>${formatMoney(moneySymbol, 20)}</th>
            </thead>
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </thead>
            <thead>
                <th>Itens</th>
                <th>${translate('columns.code')}</th>
                <th>${translate('columns.qtd')}</th>
                <th>${translate('columns.presentation')}</th>
                <th>${translate('columns.amountQtd')}</th>
                <th>${translate('columns.price')}</th>
                <th>${translate('columns.subTotal')}</th>
            </thead><tbody>`;

            const items = exportTransferItemsColumns(transferOrder?.availableItems || []);

            for (const item of items) {
                htmls += `<tr>
                            <td>${item.Item}</td>
                            <td>${item.Cod}</td>
                            <td>${item.Quantity}</td>
                            <td>${item.QtdApres}</td>
                            <td>${item.QtdTotal}</td>
                            <td>${item.Preco}</td>
                            <td>${item.Subtotal}</td>
                        </tr>`;
            }

            htmls += `</tbody></table>`;

            document.getElementById('export-div').innerHTML = htmls;

            const fileName = transferOrder.ref
                ? `${translate('general.saleOrder')} - ${transferOrder.ref
                }`
                : translate('general.saleOrder');

            const elt = document.getElementById('tbl_exporttable_to_xls');
            const wb = utils.table_to_book(elt);

            writeFile(wb, `${fileName}.` + ('xlsx' || 'xlsx'));
        }


        return (
            <>
                <Row style={{ marginTop: 20, paddingLeft: 16, paddingRight: 16, }} align="middle" justify='center'>
                    <Col xs={24}>
                        <Row justify={'space-between'} align="middle">
                            <Col xs={24} xl={8} style={{ marginTop: 8 }}>
                                <SearchGroup
                                    onChangeSelect={(v) =>
                                        filter({ filterType: v })
                                    }
                                    options={filterItemsOptions}
                                    allowClear
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    onSearch={(e) => {
                                        filter({ filterString: e });
                                    }}
                                    onChange={(e) => {
                                        filter({
                                            filterString: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={24} xl={16} style={{ marginTop: 8 }}>
                                <Row justify='end' style={{ gap: 8 }}>
                                    <Col flex={1} xl={16}>
                                        <SelectItemsWarning />
                                    </Col>
                                    <Col>
                                        <ExportButton
                                            style={{ marginLeft: 0 }}
                                            onClick={exportToExcel}
                                        >
                                            {translate('general.export')}{' '}
                                            <DownloadOutlined />
                                        </ExportButton>
                                    </Col>
                                    <SelectCampaign bonification={bonification} />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div
                    style={{
                        visibility: 'hidden',
                        width: 1,
                        height: 1,
                        overflow: 'hidden',
                    }}
                    id={'export-div'}
                ></div>
            </>
        );
    },
);
